import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ApiNames from "./Constants/ApiNames";
import { fetchPost } from "./Utils/FetchApi";
import { AwarenessScreen } from "./Screens/AwarenessScreen";

const LandingRoutes = () => {
  const search = useLocation().search;
  const [token, setToken] = useState();

  useEffect(() => {
    authJWT();
  }, []);
  const authJWT = async () => {
    try {
      let response = await fetchPost(ApiNames.generateJWT);
      if (response.status === 200) {
        setToken(response.access_token);
      } else {
        console.log("Authentication failed. Please try again.");
      }
    } catch (err) {
      console.log("Authentication failed: " + err);
    }
  };
  return (
    <>
      <Routes>
        <Route
          path="/khawab"
          element={<AwarenessScreen token={token} flag={0} />}
        />
        <Route
          path="/awareness"
          element={<AwarenessScreen token={token} flag={1} />}
        />
      </Routes>
    </>
  );
};
export default LandingRoutes;
